import wcs from './index'
import axios from 'axios'
import { getShortcodeNormalized } from '../normalizer'

export const getShortcode = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const endpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getShortcode')
      : `${domain}/wcs/resources/store/${storeId}/shortcode?langId=${langId}`

  let data = {}
  await axios.get(endpoint).then(
    response => {
      data = response
    },
    error => {
      console.error('Failed call to shortcode endpoint')
    }
  )

  return getShortcodeNormalized(data)
}
