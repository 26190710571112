// gift card balance
import axios from 'axios'
import wcs from './index'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { getErrorFromGiftCardBalance } from '../normalizer'
var http = require('http')

export const getGiftCardBalance = async (
  storeId,
  catalogId,
  langId,
  giftCardPin,
  giftCardNumber,
  gRecaptchaResponse
) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  let url = `${domain}/${getCurrentCountry()}/gift-cards/AjaxSGHCheckGiftCardBalanceCmd`
  let payLoadJson = `storeId=${storeId}&catalogId=${catalogId}&langId=${langId}&giftCardNumber=${giftCardNumber}&giftCardPin=${giftCardPin}&gRecaptchaResponse=${gRecaptchaResponse}`
  let res = {}
  let hasError

  try {
    res = await axios.post(url, payLoadJson)
    hasError =
      typeof res.data === 'string' && res.data.indexOf('/*') > -1 && res.data.lastIndexOf('*/') > -1
    return hasError ? getErrorFromGiftCardBalance(res.data) : res.data
  } catch (error) {
    console.error(error)
  }
}
