export function updateCtaPdpVisibility(isLogged) {
  let pdp = document.querySelector('#pdp')
  let pdpStickyHeader = document.querySelector('.pdp-sticky-header')

  const loginCta = pdp.querySelector('.exclusive-login-cta')
  const ctaConfiguratorRx = pdp.querySelector('.sgh-pdp__configurator.sgh-pdp__rx--button')
  const ctaConfiguratorRemix = pdp.querySelector('.sgh-pdp__configurator.customize')
  const ctaFindInStore = pdp.querySelector('#findInStore')
  const ctaAddToBag = pdp.querySelector('.ctaAddToCart')
  const loginCtaSticky = pdpStickyHeader.querySelector('.exclusive-login-cta-sticky')
  const ctaAddToBagSticky = pdpStickyHeader.querySelector('.ajaxAddToCart')

  if (ctaConfiguratorRx) {
    isLogged
      ? ctaConfiguratorRx.classList.remove('hidden')
      : ctaConfiguratorRx.classList.add('hidden')
  }
  if (ctaConfiguratorRemix) {
    isLogged
      ? ctaConfiguratorRemix.classList.remove('hidden')
      : ctaConfiguratorRemix.classList.add('hidden')
  }
  if (ctaFindInStore) {
    isLogged ? ctaFindInStore.classList.remove('hidden') : ctaFindInStore.classList.add('hidden')
  }
  if (ctaAddToBag) {
    if (isLogged) {
      ctaAddToBag.classList.remove('hideCtaAddToBag')
      ctaAddToBag.removeAttribute('aria-hidden')
    } else {
      ctaAddToBag.classList.add('hideCtaAddToBag')
      ctaAddToBag.setAttribute('aria-hidden', true)
    }
  }
  if (loginCta) {
    isLogged ? loginCta.classList.add('hidden') : loginCta.classList.remove('hidden')
  }
  if (loginCtaSticky) {
    isLogged ? loginCtaSticky.classList.add('hidden') : loginCtaSticky.classList.remove('hidden')
  }

  if (ctaAddToBagSticky) {
    if (isLogged) {
      ctaAddToBagSticky.classList.remove('hideCtaAddToBag')
      ctaAddToBagSticky.removeAttribute('aria-hidden')
    } else {
      ctaAddToBagSticky.classList.add('hideCtaAddToBag')
      ctaAddToBagSticky.setAttribute('aria-hidden', true)
    }
  }
}
