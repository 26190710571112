export const getInlineJSON = function(id) {
  const scriptEl = document.getElementById(id)
  try {
    return JSON.parse(scriptEl.innerText)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`missing or bad JSON in <script id="${id}" type"application/json"> in the DOM`)
  }

  return {}
}

export const getLabelsJSON = function() {
  const scriptsEl = document.querySelectorAll('[data-inline-labels]')
  var labels = {}
  for (var node of scriptsEl) {
    try {
      labels = { ...labels, ...JSON.parse(node.innerText) }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error parsing inline labels JSON[${node.id}] - ${e.message}`)
    }
  }

  return labels
}
