export function setCountdown(endDate, type, currentDate) {
  var end = endDate

  const _second = 1000
  const _minute = _second * 60
  const _hour = _minute * 60
  const _day = _hour * 24

  const now = currentDate !== undefined ? currentDate : new Date()
  const distance = end - now

  const days = Math.floor(distance / _day)
  const hours = Math.floor((distance % _day) / _hour)
  const minutes = Math.floor((distance % _hour) / _minute)
  const seconds = Math.floor((distance % _minute) / _second)
  const effectiveDays = Math.floor(distance / (_hour * 24))

  if (effectiveDays < 1) {
    return type === 'days'
      ? `${hours}h ${minutes}m  ${seconds}s`
      : `${hours} : ${minutes} : ${seconds}`
  } else {
    return type === 'days'
      ? `${days}d ${hours}h ${minutes}m ${seconds}s`
      : `${days} : ${hours} : ${minutes} : ${seconds}`
  }
}
