import {
  ColorVariantsType
} from '../../store/types/pdp.types'

import {
  normalizeProductPrice
} from '../../libs/algolia-normalizer'

export const normalizeProductsForVirtualMirror = (products: ColorVariantsType[], frameLabel: string, lensLabel: string, productBrand: string) => {
  return products.map(product => ({
    brand: {
      name: productBrand || '',
      logoUrl: product.brandLogoUrl || '',
    },
    catentryId: product.productId,
    code: product.model,
    colorCode: '',
    frameColorLabel: `${frameLabel}: ${product.frameColor}`,
    lensColorLabel: `${lensLabel}: ${product.lensColor}`,
    name: product.model,
    thumbnailUrl: product.variantImageUrl,
    upc: product.upc,
    url: product.url,
  }))
}

export const normalizePricesForVirtualMirror = (products: ColorVariantsType[]) => {
  return products.map(product => {
    const productPriceObj = normalizeProductPrice(product.prices)
    
    if (!productPriceObj) {
      throw new Error('cannot retrieve correct price for Virtual Mirror configurator')
    }

    const { currency, discountedPrice, fullPrice } = productPriceObj
    const isDiscounted = discountedPrice < fullPrice

    return {
      currency,
      current: {
        text: `${isDiscounted ? discountedPrice : fullPrice}`,
        value: isDiscounted ? discountedPrice : fullPrice,
      },
      previous: isDiscounted ? {
        text: `${fullPrice}`,
        value: fullPrice,
      } : undefined,
      upc: product.upc,
    }
  })
}