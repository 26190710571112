import wcs from './index'
import { getEarlyAccessSubscribeError } from '../normalizer'
import axios from 'axios'

export const subscribeUser = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { langId, storeId } = wcs.getStoreConfig()

  const url = `${domain}/wcs/resources/store/${storeId}/user/subscribe?langId=${langId}`
  let data = {}

  try {
    data = await axios.post(url, parameters)
    return data.data
  } catch (error) {
    if (error.response) {
      console.log(error.response.data)
      if (error.response.data.messages) {
        return getEarlyAccessSubscribeError(error.response)
      }
    } else {
      return 'Error'
    }
  }
}
