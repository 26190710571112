import { VideoElement } from '../../../types/Common.types';

export function toggleVideo(e: MouseEvent, isNotRef: boolean = false): void {
    if (e.target !== null) {
        
        const target = isNotRef ? e as unknown as HTMLElement : e.target as HTMLElement;
        const video: VideoElement | null = isNotRef ? (e as unknown as HTMLElement).nextElementSibling as VideoElement : (e.target as HTMLElement).nextElementSibling as VideoElement;
        
        const updateIcon = (isOnVideo: boolean = false) => {
            const videoCheck = isOnVideo ? !video?.paused : video?.paused;
            if (videoCheck) {
                target.classList.add('common__icon--play-video');
                target.classList.remove('common__icon--stop-video');
            } else {
                target.classList.add('common__icon--stop-video');
                target.classList.remove('common__icon--play-video');
            }
        };
        updateIcon(false);
        if (video && video.paused) {
            video.play();
        } else if (video) {
            video.pause();
        }
        if (video) {
            video.addEventListener('play', () => updateIcon(true));
            video.addEventListener('pause', () => updateIcon(true));
        }
    }
}