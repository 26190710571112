import axios from 'axios'
import wcs from './index'

import {
  DataPrescriptionType
} from '../../store/types/prescription.types'

//redirectToCartPage
const redirectToCartPage = () => {
  const hostname = window.location.hostname
  const { storeId, langId, catalogId } = wcs.getStoreConfig()
  const hrefCartPage = `https://${hostname}/OrderCalculate?errorViewName=AjaxOrderItemDisplayView&catalogId=${catalogId}&langId=${langId}&URL=AjaxOrderItemDisplayView&storeId=${storeId}&orderId=.`

  window.location.href = hrefCartPage
}

//recalculateOrderPromotion
const recalculateOrderPromotion = (orderId: string) => {
  const hostname = window.location.hostname
  const { storeId } = wcs.getStoreConfig()
  const url = `https://${hostname}/webapp/wcs/stores/servlet/AjaxOrderCalculate?storeId=${storeId}&orderId=${orderId}&calculationUsageId=-1&calculationUsageId=-3`

  axios.get(url).finally(() => redirectToCartPage())
}

//addToCartCall
const addToCartCall = (url: string, params: any) => {
  const { langId } = wcs.getStoreConfig()
  axios
    .post(url, params)
    .then((resp: any) => {
      if (params.warrantyObject && params.warrantyObject.id) {
        const paramsWarranty = {
          langId: langId,
          page: 'pdp',
          catalogOrigin: '',
          catEntryId: params.warrantyObject.id,
          parentItemId: resp.data.frameOrderItemId
        }
        window.categoryDisplayJS.AddItem2ShopCartAjax(params.warrantyObject.id, 1, paramsWarranty)
      }
      recalculateOrderPromotion(resp.data.OrderId[0])
    })
    .catch(err => {
      console.error(err)
    })
}

//rxAddToCart
window.rxAddToCart = (_frameObject: any, _lensObject: any, _warrantyObject: any) => {
  const hostname = window.location.hostname
  const { storeId } = wcs.getStoreConfig()
  const urlStr = `https://${hostname}/webapp/wcs/stores/servlet/CreateGuestUser?storeId=${storeId}`
  const url = `https://${hostname}/wcs/resources/store/${storeId}/rx/cart`
  let data: DataPrescriptionType = {
    frameObject: {},
    lensObject: {},
    warrantyObject: {}
  }

  data.frameObject = _frameObject
  data.lensObject = _lensObject
  if (_warrantyObject && Object.keys(_warrantyObject).length > 0) {
    data.warrantyObject = _warrantyObject
  }

  axios
    .get(urlStr)
    .then(() => {
      addToCartCall(url, data)
    })
    .catch(err => {
      console.error(err)
    })
}

//rxAddToWishlist
window.rxAddToWishlist = function (_frameObject: any, _lensObject: any, _warrantyObject: any) {
  const hostname = window.location.hostname
  const { storeId } = wcs.getStoreConfig()
  const url = `https://${hostname}/wcs/resources/store/${storeId}/wishlist/sgh/rox/add-to-wishes`
  let data: DataPrescriptionType = {
    frameObject: {},
    lensObject: {},
    warrantyObject: {},
  }

  data.frameObject = _frameObject
  data.lensObject = _lensObject

  axios
    .post(url, data)
    .then(() => {
      if (window.wishlist && window.wishlist.$store) {
        window.wishlist.$store
          .dispatch('wishlist/ACTION_COUNT_WISHLIST_ITEM', {})
          .then(() => {
            var userIsGuest =
              window.constants &&
              window.constants.ajaxParams.userType.length != 0 &&
              window.constants.ajaxParams.userType === 'G'

            if (
              userIsGuest &&
              window.localStorage.getItem(`SGH_WISHLISTITEMS_${window.currentCountry()}`) === '1'
            ) {
              window.localStorage.setItem(`SGH_WISHLISTFIRSTITEM_${window.currentCountry()}`, 'true')
              document.querySelector('.dl-menuwrapper')?.classList.add('firstItemWishlist')
            }
          })
          .catch((e: any) => console.error(e))
      }
    })
    .catch(err => {
      console.error(err)
    })
}

//getRXContent
const getRXContent = async (productUpc: string) => {
  const { storeId, locale } = wcs.getStoreConfig()
  const env = window.origin
  const assetsDir = window.assetsDir || '/wcsstore/MadisonsStorefrontAssetStore/'

  try {
    window.rxcTranslations = await fetch(
      `${assetsDir}translations/prescription_${locale}.json`
    ).then(res => (res.ok ? res.json() : null))
  } catch (e) {
    console.error(e)
  }

  try {
    return await fetch(
      `${env}/wcs/resources/store/${storeId}/rox/lensesData/${productUpc}`
    ).then(res => (res.ok ? res.json() : null))
  } catch (e) {
    console.error(e)
  }
}

//set config
const config = {
  translation: { language: wcs.getStoreConfig().locale },

  /* MANDATORY lens actions, needed to save, edit and add to cart */
  actionsModule: {
    enableFullLensData: true,
    genericAddToCart: function (_frameObject: any, _lensObject: any, _warrantyObject: any, _reviewObject:any, _imagery: any) {
      if (_imagery) {
        localStorage.setItem('rxImageThumbnailUrl', _imagery.productImage)
      }
      window.rxAddToCart(_frameObject, _lensObject, _warrantyObject)
    },
    genericSaveEditFromCart: function (
      _frameObject: any,
      _lensObject: any,
      _warrantyObject: any,
      _cartMode: any,
      _reviewObject: any,
      _imagery: any
    ) {
      const { storeId, langId, catalogId } = wcs.getStoreConfig()
      var params = {
        storeId: '',
        catalogId: '',
        langId: '',
        orderItemId: '',
        calculationUsage: ''
      }

      params.storeId = storeId
      params.catalogId = catalogId
      params.langId = langId
      params.orderItemId = _cartMode.orderItemId
      params.calculationUsage = '-1,-2,-3,-4,-5,-6,-7'

      window.wc.service.invoke('AjaxDeleteOrderItem1', params)
      this.genericAddToCart(_frameObject, _lensObject, _warrantyObject, _reviewObject, _imagery)
    },
    genericSaveLensSelection: function (_frameObject: any, _lensObject: any, _warrantyObject: any) {
      window.rxAddToWishlist(_frameObject, _lensObject, _warrantyObject)
    },
    loadLearnMoreContent: function (_contentName: string) {
      // create Promise object as return
      const env = window.origin
      const { storeId } = wcs.getStoreConfig()
      const url = `${env}/wcs/resources/store/${storeId}/espot/${_contentName}`

      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest()

        // send async request
        xhr.onload = function () {
          var response = new Response(xhr.response, { status: 200 })

          // parse the response MarketingSpotData object
          response.json().then(function (data) {
            if (
              data.MarketingSpotData &&
              data.MarketingSpotData.length > 0 &&
              data.MarketingSpotData[0].baseMarketingSpotActivityData &&
              data.MarketingSpotData[0].baseMarketingSpotActivityData.length > 0 &&
              data.MarketingSpotData[0].baseMarketingSpotActivityData[0]
                .marketingContentDescription &&
              data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription
                .length > 0 &&
              data.MarketingSpotData[0].baseMarketingSpotActivityData[0]
                .marketingContentDescription[0].marketingText
            ) {
              // isolate the html code
              let codeHtml =
                data.MarketingSpotData[0].baseMarketingSpotActivityData[0]
                  .marketingContentDescription[0].marketingText

              // return the html code in the resolve
              resolve(codeHtml)
            }
          })
        }
        xhr.onerror = function () {
          reject(new TypeError('Local request failed'))
        }
        xhr.open('GET', url)
        xhr.responseType = 'arraybuffer'
        xhr.send(null)
      })
    },
    genericExit: function () {
      // optional function to trigger events on configurator closure
    },
    loadContent: function (_loadContentName: string) {
      // create Promise object as return
      return new Promise(function (resolve, reject) {
        resolve(_loadContentName)
      })
    },
  },

  brand: '',
  cartMode: {
    catEntryId: '',
    orderIndex: 0,
    orderItemId: '',
  },
  data: {
    lens: null
  },
  lensesData: '',
  paymentInstallment: {},
  selector: '',
  linksData: {
    warrantyLearnMore: window.rxEPPWarantyURL,
  },

  /* OPTIONAL to differentiate included images */
  baseURLs: {
    genericImage: window.rxGenericImgBasePath || '',
    projectImage: window.rxImgBasePath || '',
    framesImage: window.akamaiImgBasePath || '',
    assetsCDN: window.rxCDNImgBasePath || '',
  },
}

//render Widget
const renderWidget = () => {
  var myWidget: { render?: () => {} } = window?.RXC && window.RXC?.rxcWidget.new(config)
  myWidget.render && myWidget.render()
}

//goRender
export const goRender = async (param: any, callback: any) => {
  callback(true)
  const rxButton = document.getElementById('add-prescription')

  config.selector = '#rxcApp'
  config.paymentInstallment = {
    types: ['afterpay', 'klarna'],
    installments: 4,
    multiplePaymentInstallment: true,
    contentIdentifier: '',
  }
  const rxContent = await getRXContent(param?.productUPC)
  if (rxContent) {
    config.data = rxContent.data
    if (param?.lensObject) {
      config.data.lens = param?.lensObject
    }
    if (param?.catEntryId) {
      config.cartMode.catEntryId = param?.catEntryId
    }
    if (param?.orderItemId) {
      config.cartMode.orderItemId = param?.orderItemId
      config.cartMode.orderIndex = 0
    }
    config.lensesData = rxContent.lensesData
    config.brand = rxContent.brand

    //check widget initialization
    if (rxButton?.getAttribute('initialized')) {
      renderWidget()
      callback(false)
    } else {
      //add style file
      const cssNode = document.createElement('link')
      cssNode.setAttribute('rel', 'stylesheet')
      cssNode.setAttribute('type', 'text/css')
      cssNode.setAttribute('href', window.rxCSSPath)

      //add js file
      const tag = document.createElement('script')
      tag.src = window.rxScriptPath
      tag.defer = true
      if (rxButton) {
        rxButton?.before(cssNode)
        rxButton?.before(tag)
      } else {
        //include css and js script in head tag when edit from cart
        document.head.appendChild(cssNode)
        document.head.appendChild(tag)
      }
      //wait file loading
      tag.onload = () => {
        rxButton?.setAttribute('initialized', 'true')
        renderWidget()
        callback(false)
      }
    }
    return
  }
  callback(false)
}
