/* eslint-disable class-methods-use-this */
var debug = require('debug')('tracking:tealium')
import BasicModule from '../vue-multianalytics/modules/BasicModule'

// class BasicModule {
//   constructor(name, config = {}) {
//     this.name = name
//     this.config = config
//   }

//   trackView() {
//     /* Overriden by modules */
//   }

//   trackEvent() {
//     /* Overriden by modules */
//   }

//   trackException() {
//     /* Overriden by modules */
//   }

//   trackTiming() {
//     /* Overriden by modules */
//   }

//   setAlias() {
//     /* Overriden by modules */
//   }

//   identify() {
//     /* Overriden by modules */
//   }

//   setUsername() {
//     /* Overriden by modules */
//   }

//   setUserProperties() {
//     /* Overriden by modules */
//   }

//   setUserPropertiesOnce() {
//     /* Overriden by modules */
//   }

//   incrementUserProperties() {
//     /* Overriden by modules */
//   }

//   setSuperProperties() {
//     /* Overriden by modules */
//   }

//   setSuperPropertiesOnce() {
//     /* Overriden by modules */
//   }

//   ecommerceTrackEvent() {
//     /* Overriden by modules */
//   }

//   addTransaction() {
//     /* Overriden by modules */
//   }

//   addItem() {
//     /* Overriden by modules */
//   }

//   trackTransaction() {
//     /* Overriden by modules */
//   }

//   clearTransactions() {
//     /* Overriden by modules */
//   }

//   reset() {
//     /* Overriden by modules */
//   }
// }

export default class TealiumTrackingModule extends BasicModule {
  init() {
    // ...
    debug('setup tracker')
  }

  trackView({ viewName }) {
    debug('trackview %s', viewName)

    // Add tealium tracking call
    // myowntrack('Page Viewed', { page: viewName })
  }

  trackEventSPA(data) {
    if (window.utag !== undefined) {
      window.utag.ut.merge(window.utag.data, data, 1)
      window.utag.view(window.utag.data)
    }
  }

  trackEvent({ event, value = {} }) {
    var obj
    try {
      obj = {
        ...value,
        id: event,
      }
      debug('event %s - %o', event, value)
    } catch (err) {
      obj = {
        id: 'Error',
        Error_Source: 'Server',
        Error_Code: 'utag_data syntax - ' + err.message,
        Error_Detail: '',
      }
    } finally {
      // eslint-disable-next-line no-undef
      window.tealium_data2track.push(obj)
    }
  }

  trackException({ source, code, detail = '', message = '' }) {
    debug('error %s | %s | %s', source, code, detail, message)
    if (window.tealium_data2track !== undefined) {
      window.tealium_data2track.push({
        id: 'Error',
        Error_Source: source,
        // Error short description always in English:  e.g. "Loading error" or "404 - page not found" for source "404"
        Error_Code: code,
        // Error cause/source. Must specify details to drive IT through an exact fix. In case of Source "404" write the requested URL that generated the issue. In case of Source "Product" must be the requested product UPC.
        Error_Details: detail,
        Error_Message: message,
      })
    }
  }
}
