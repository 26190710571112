const endpoints = window.wcs_endpoints
const envConfig = window.env_config

export default {
  getEnvConfig(key) {
    return envConfig ? envConfig[key] : false
  },
  getEndPoint(key) {
    return endpoints[key]
  },
  getStoreConfig() {
    const {
      storeId = null,
      catalogId = null,
      langId = null,
      orderId = null,
      isAsianFitAvailable = false,
      brand = 'SGH',
      locale = 'en_US',
      currency = 'USD',
      openMenu = false,
      browsers = [],
      browserVersions = '{}',
      globalCountry = window.globalCountry || 'US',
    } = window.wcs_config

    return {
      storeId,
      catalogId,
      langId,
      orderId,
      brand,
      locale,
      currency,
      openMenu,
      browsers,
      browserVersions: JSON.parse(browserVersions),
      globalCountry,
      isAsianFitAvailable:
        isAsianFitAvailable && isAsianFitAvailable.toLowerCase() === 'true' ? true : false,
    }
  },

  getOrderConfig() {
    const { allowedOrderStatuses = [] } = window.order_config

    return {
      allowedOrderStatuses,
    }
  },

  // TODO: REMOVE IT AFTER CHECKED IF EXISTS
  getProductsColorJson() {
    return window.productsJsonObj || {}
  },

  getUserStatus() {
    return window.constants && window.constants.ajaxParams.userType.length != 0
      ? window.constants.ajaxParams.userType
      : 'G'
  },

  isUserLogged() {
    const loggedUser = 'R'
    return this.getUserStatus() === loggedUser
  },

  isTYP() {
    return window.pageType === 'purchase'
  },

  isPDP() {
    return window.pageType === 'product'
  },

  isPLP() {
    return window.pageType === 'category'
  },

  isHomePage() {
    return window.pageType === 'main'
  },
}
