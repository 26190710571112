export function updateCounterMinicart(counter) {
  let btnCart = document.querySelector('.main-menu-right__link.btn-cart')
  btnCart ? btnCart.setAttribute('aria-label', counter) : null
  if (counter > 0) localStorage.setItem('SGH_CARTITEMS', counter)
  let setCounter = document.querySelectorAll('header .cart-counter')
  if (setCounter.length > 0 && counter > 0) {
    setCounter.forEach($element => {
      if ($element.classList.contains('hide')) $element.classList.remove('hide')
      $element.innerHTML = counter
    })
  }
}

export function addExclusiveToBag() {
  const addExclusiveToBag = window.localStorage.getItem('addExclusiveToBag') ? true : false
  if (addExclusiveToBag) {
    const addExclusiveToBagValues = JSON.parse(window.localStorage.getItem('addExclusiveToBag'))

    if (!addExclusiveToBagValues.addToCart) {
      addExclusiveToBagValues.addToCart = true
      localStorage.setItem('addExclusiveToBag', JSON.stringify(addExclusiveToBagValues))
    }
  }
}
