export function animationHeaders() {
  let lastScrollTop = 0

  let headerMenu = document.querySelector(`.sgh-main-menu__wrapper.${deviceClass()}`)
  let isPdp = document.querySelector('.sgh-pdp')
  window.addEventListener('resize', () => {
    headerMenu = document.querySelector(`.sgh-main-menu__wrapper.${deviceClass()}`)
  })

  window.addEventListener(
    'scroll',
    () => {
      const bodySelectorClasses = document.querySelector('body').classList
      if (
        !bodySelectorClasses.contains('bodyPlp') &&
        !(bodySelectorClasses.contains('my-account') && isMobile()) &&
        !document.querySelector('body .X_LP_SizeGuide') &&
        !bodySelectorClasses.contains('overflow-hidden') &&
        headerMenu
      ) {
        let globalHeader = document.querySelector('.sgh-header')
        let globalHeaderBanner = document.querySelector('.sgh-header-top')
        let storeLocatorContentTop = document.querySelector('.sgh-sl-content-top')
        const st = window.pageYOffset || document.documentElement.scrollTop

        if (storeLocatorContentTop && isMobile)
          st > lastScrollTop && lastScrollTop >= headerMenu.clientHeight
            ? storeLocatorContentTop.classList.add('scroll-down')
            : storeLocatorContentTop.classList.remove('scroll-down')

        if (st > lastScrollTop && lastScrollTop >= headerMenu.clientHeight) {
          if (headerMenu.classList.contains('sgh-main-menu__top')) {
            headerMenu.classList.remove('sgh-main-menu__top')
          }
          headerMenu.classList.add('sgh-main-menu__down')
          if (globalHeaderBanner != null)
            globalHeader.style.height = `${globalHeaderBanner.clientHeight}px`
        } else if (st < lastScrollTop && lastScrollTop >= headerMenu.clientHeight) {
          if (headerMenu.classList.contains('sgh-main-menu__down')) {
            headerMenu.classList.remove('sgh-main-menu__down')
          }
          headerMenu.classList.add('sgh-main-menu__top')

          if (globalHeaderBanner != null && !isPdp) {
            globalHeader.style.height = isMobile()
              ? `${globalHeaderBanner.clientHeight + headerMenu.clientHeight}px`
              : `${headerMenu.clientHeight}px`
          }
        }
        lastScrollTop = st <= 0 ? 0 : st
      }
    },
    false
  )
}
function deviceClass() {
  return window.innerWidth > 1024 ? 'desktop' : 'mobile'
}
function isMobile() {
  return window.innerWidth <= 1024
}
