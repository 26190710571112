//URLS
export const REACT_DOM = 'https://unpkg.com/react-dom@17/umd/react-dom.production.min.js'
export const REACT_PROD = 'https://unpkg.com/react@17/umd/react.production.min.js'
export const WL_RELEASE = '2.1'
export const WL_URL = 'https://frameadvisor.luxottica.com/v/2.1/index.umd.js'

// FRAME GENIUS
export const FA_REACT_DOM_URL = sessionStorage.getItem('FA_REACT_DOM_URL')
export const FA_REACT_PROD_URL = sessionStorage.getItem('FA_REACT_PROD_URL')
export const FA_WL_URL = sessionStorage.getItem('FA_WL_URL')
export const FRAME_GENIUS = 'frame-genius'
export const FRAME_GENIUS_PICTURE_MODE = '#/picture-mode'
export const FRAME_GENIUS_PRODUCTS_ROWS_LIMIT = 100
export const FRAME_GENIUS_PROFILE = '#/profile'
export const FRAME_GENIUS_USER_DATA = 'frameGeniusUserData'
export const FRAME_GENIUS_USER_PROFILE_DATA = 'frameGeniusUserProfileData'
export const FRAME_GENIUS_VIRTUAL_MIRROR_KEY = '960add90-12b7-46d7-852f-4e08fa2fa128'
export const INIT_FRAME_GENIUS = 'initFrameGenius'
export const IS_FA_EXPERIENCE_DONE = sessionStorage.getItem('isFaExperienceDone') == 'true'
export const IS_FA_MULTIBRAND = true

// SIZE ADVISOR
export const INIT_SIZE_ADVISOR = 'initSizeAdvisor'
export const IS_SA_EXPERIENCE_DONE = sessionStorage.getItem('isSaExperienceDone') == 'true'
export const SA_REACT_PROD_URL = sessionStorage.getItem('SA_REACT_PROD_URL')
export const SA_REACT_DOM_URL = sessionStorage.getItem('SA_REACT_DOM_URL')
export const SA_WL_URL = sessionStorage.getItem('SA_WL_URL')
export function SA_LOAD_EXTERNAL_LIBRARIES() {
  const reactScript = document.createElement('script')
  reactScript.setAttribute('src', SA_REACT_PROD_URL)
  reactScript.setAttribute('crossorigin', '')
  reactScript.setAttribute('type', 'text/javascript')
  document.body.appendChild(reactScript)
  const reactDomScript = document.createElement('script')
  reactDomScript.setAttribute('src', SA_REACT_DOM_URL)
  reactDomScript.setAttribute('crossorigin', '')
  reactDomScript.setAttribute('type', 'text/javascript')
  reactScript.addEventListener('load', () => document.body.appendChild(reactDomScript))
  const frameGeniusScript = document.createElement('script')
  frameGeniusScript.setAttribute('src', SA_WL_URL)
  frameGeniusScript.setAttribute('type', 'text/javascript')
  reactDomScript.addEventListener('load', () => document.body.appendChild(frameGeniusScript))
  frameGeniusScript.addEventListener('load', () => {
    const event = new Event('WL_libraries_loaded')
    document.dispatchEvent(event)
    return
  })
}

export const VIRTUAL_MIRROR_SCRIPT = 'https://vmmv.luxottica.com/v/4.17/index.umd.js'
