export const getCheckedList = function(input){
  // eslint-disable-next-line no-unused-vars
  const { list, label, groupName, ...others } = input
  let output = []
  if(list){
    output = output.concat(list.map(value => {
      let item = {}
      item[value.id] = false
      return item
    }))
  }
  if(others){
    Object.keys(others).forEach(element => {
      // const obtained = getCheckedList(others[element])
      output = output.concat(getCheckedList(others[element]))
      // console.log('obtained', obtained)
    })
  }
  return output
}
