import algoliasearch from 'algoliasearch'
import i18n from '../../i18n-setup'
import { loadSectionTranslations } from '../../i18n-setup'
import { getAlgoliaFormattedPrice, isDateValid } from '../../libs/algolia-normalizer'
import { getCurrentLocale } from '../../libs/utils/currentCountry'
import isEmpty from 'lodash/isEmpty'
import { ALGOLIA_CONFIG, getUngroupedIndex } from './algoliaIntegration'

const now = Date.now()
let client = algoliasearch(ALGOLIA_CONFIG.applicationId, ALGOLIA_CONFIG.apiKey)


function getValueByPath(obj: any, path: string): any {
    // Split the path by '.' to get individual keys
    const keys = path.split('.');
    // Reduce the keys to access the nested value
    return keys.reduce((acc, key) => acc && acc[key] ? acc[key] : undefined, obj);
}
const offerDateValid = (price: any, path: any) => {
    const pathNoOfferPrice = path.replace(/\.offerPrice$/, '')
    const startDate = price[pathNoOfferPrice]?.startDate
    const endDate = price[pathNoOfferPrice]?.endDate
    return startDate && endDate ? isDateValid(startDate, endDate) : false
}

export const getRecentlySearchedProductNormalizer = (product: any) => {
    loadSectionTranslations('plp')
    let outputItem: any = {}
    const locale = getCurrentLocale() || 'en-US'

    const currency = product.prices.RxPriceList_SGH_US?.currency || 'USD'

    const getImageUrl = (images: any, index: any) => images[index]?.url
        ? `${images[index]?.url}?impolicy=SGH_bgtransparent&width=640`
        : undefined
    outputItem.img = getImageUrl(product.images, 0) || ''
    outputItem.imgHover = getImageUrl(product.images, 1) || ''
    outputItem.imgPreview = outputItem.imgHover || ''

    outputItem.brand = product.brand || ''
    outputItem.modelName = product.model || ''

    const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}

    const offerPriceValueKey = priceNameConfig.OfferPrice
    const listPriceValueKey = priceNameConfig.ListPrice

    const discountedPrice = getValueByPath(product.prices, offerPriceValueKey)
    const listPrice = getValueByPath(product.prices, listPriceValueKey)
    
    const isOfferPriceDateValid = offerDateValid(product.prices, listPriceValueKey)
    if (discountedPrice && discountedPrice < listPrice && isOfferPriceDateValid) {
        outputItem.price = getAlgoliaFormattedPrice(discountedPrice, locale, currency) || undefined
        outputItem.priceRaw = discountedPrice || undefined
        outputItem.onSale = {
            originalPrice: getAlgoliaFormattedPrice(listPrice, locale, currency) || undefined,
        }
    } else {
        outputItem.price = listPrice
            ? getAlgoliaFormattedPrice(listPrice, locale, currency)
            : discountedPrice
                ? getAlgoliaFormattedPrice(discountedPrice, locale, currency)
                : i18n.t('plp_pending_price')
        outputItem.priceRaw = listPrice || undefined
    }


    outputItem.pdpURL = product.links.url
    outputItem.catentryId = product.productId
    outputItem.upc = product.upc

    const isPresentWishlist = { isInWishlist: false }
    outputItem.wishlistRelatedLinks = isPresentWishlist

    if (!isEmpty(product.colors)) {
        outputItem.colorsNumber =
            product.colors.length === 0 ? 0 : ` ${product.colors.length} ${i18n.t('plp_colors')}`
        outputItem.colorCount = outputItem.colorsNumber
    }

    return outputItem
}

export const algoliaRecentlySearched = async (searchTerm: string[]): Promise<any> => {
    const queries = searchTerm.map(searchValue => ({
        hitsPerPage: 25,
        indexName: getUngroupedIndex('default'),
        facetFilters: [],
        numericFilters: [],
        ruleContexts: [],
        clickAnalytics: true,
        query: searchValue || '',
    }));

    const maxChunkSize = 50
    const results = [];
    while (queries.length > 0) {
        const chunk = queries.splice(0, maxChunkSize);
        const chunkResults = await client.multipleQueries(chunk);
        results.push(...chunkResults.results);
    }

    const filteredResults = results.map((result: any) => {
        return result.hits[0]
    })
    
    return filteredResults
}