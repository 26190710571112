import algoliasearch from 'algoliasearch'
import algoliarecommend from '@algolia/recommend'
import aa from 'search-insights'
import wcs from '../wcs'
import map from 'lodash/map'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import filter from 'lodash/filter'
import takeRight from 'lodash/takeRight'
import isEmpty from 'lodash/isEmpty'
import invert from 'lodash/invert'
import split from 'lodash/split'
import some from 'lodash/some'
import findIndex from 'lodash/findIndex'
import update from 'lodash/update'
import { getCurrentLocale } from './currentCountry'
import i18n from './../../i18n-setup'
import { loadSectionTranslations } from './../../i18n-setup'
import VueCookies from 'vue-cookies'
import { getProductsVariants, buildNumericFilters } from '../wcs/plp'

let currentLocale = getCurrentLocale().toLowerCase() || 'en-us'
if (currentLocale == 'en-uk') currentLocale = 'en-gb'
let indexEnv =
  window.algoliaConfig?.indexEnv === 'uat_auth'
    ? 'uat_live'
    : window.algoliaConfig?.indexEnv || 'uat_live'
const apiKey = window.algoliaConfig?.apiKey
const appId = window.algoliaConfig?.appId
window.algoliaConfig['isEarlyAccessParam'] = VueCookies.get('earlyAccess')
let queryIDGrouped = []
let objectIDsMapUnGrouped = []
let objectIDsMapPositionGrouped = []
let isSearch = false

export const ALGOLIA_CONFIG = {
  applicationId: appId,
  apiKey: apiKey,
  groupedIndex: `${indexEnv}_sgh_${currentLocale}__grouped`,
  ungroupedIndex: `${indexEnv}_sgh_${currentLocale}__ungrouped`,
  contentIndex: `${indexEnv}_sgh_${currentLocale}__contents`,
}

let client = algoliasearch(ALGOLIA_CONFIG.applicationId, ALGOLIA_CONFIG.apiKey)
let clientRelated = algoliarecommend(ALGOLIA_CONFIG.applicationId, ALGOLIA_CONFIG.apiKey)
let index = client.initIndex(ALGOLIA_CONFIG.groupedIndex)

//grouped search used in plp for first load of products and products update
export const algoliaPlpGroupedSearch = async payload => {
  let { options, addParams } = payload
  let minHinge, maxHinge
  options.facetFilters.map((item, index) => {
    if (item[0].includes('HINGE')) {
      const matchValue = item[0].match(/\{([^}]+)\}/)
      const valuesArray = matchValue[1].split(',').map(value => parseInt(value.trim(), 10))
      minHinge = valuesArray[0]
      maxHinge = valuesArray[1]
      const hingeValue = getHingeDistanceArrayValue(minHinge, maxHinge)
      let hingeArray = []
      hingeValue.forEach(value => {
        hingeArray.push(`attributes_translated.HINGE_DISTANCE:${value}`)
      })
      options.facetFilters[index] = hingeArray
    }
  })

  setGroupedIndex(options.orderBy)
  var res = {}
  res.hits = []
  res.hitsPerPage = 0
  var queries = []
  const startPage =
    addParams && typeof addParams.startPage !== 'undefined' ? addParams.startPage : options.page - 1
  // an error on wcs is duplicating params, if this happens we consider only first element of array: https://abstractsrl.atlassian.net/browse/LXSGH21R-8544
  if (Array.isArray(options.hitsPerPage)) {
    options.hitsPerPage = options.hitsPerPage[0]
  }
  const isNotChanel = options.query ? 'SAFEBRANDSEARCH' : ''
  const mergedRuleContexts = options.ruleContexts?.split(',').concat(isNotChanel)
  //use multipleQueries because page can be > 1 and need to retrieve 18 * page products
  for (let page = startPage; page < options.page; page++) {
    queries.push({
      indexName: index.indexName,
      hitsPerPage: options.hitsPerPage,
      facetFilters: options.facetFilters,
      numericFilters: options.numericFilters,
      ruleContexts: mergedRuleContexts,
      page: page,
      query: options.query,
      clickAnalytics: true,
    })
  }

  //split queries because algolia allows max 50 in multipleQueries api
  const maxChunkSize = 50
  const results = []
  while (queries.length > 0) {
    const chunk = queries.splice(0, maxChunkSize)
    const chunkResults = await client.multipleQueries(chunk)
    results.push(...chunkResults.results)
  }

  //build useful data for pagination and load more component
  const lastEl = results.length - 1
  res.page = results[lastEl].page
  res.hitsPerPage = results[lastEl].hitsPerPage * results.length
  res.nbPages = results[lastEl].nbPages
  res.nbHits = results[lastEl].nbHits
  results.forEach(page => {
    res.hits.push(...page.hits)
  })

  //useful data for analytics
  res.queryID = results[lastEl].queryID
  res.indexName = results[lastEl].index
  res.isSearch = results[lastEl].query !== ''
  //only the last 18 elements
  res.objectIDs = map(takeRight(res.hits, 18), 'objectID')
  objectIDsMapPositionGrouped = map(res.objectIDs, function(hit, index) {
    return { index: index + 1, objectID: hit }
  })
  queryIDGrouped = res.queryID
  isSearch = res.isSearch
  res.renderingContent = results[0].renderingContent
  res.userData = results[0].userData
  return res
}

//ungrouped search used in plp to retrieve variants
export const algoliaPlpUngroupedSearch = async ({ products, options }) => {
  const { hits } = products

  const isNotChanel = options.query ? 'SAFEBRANDSEARCH' : ''
  const mergedRuleContexts = options.ruleContexts?.split(',').concat(isNotChanel)

  const queries = hits.map(hit => ({
    hitsPerPage: 25,
    indexName: getUngroupedIndex(options.orderBy),
    facetFilters: options.facetFilters.concat([`x_groupkey: ${hit.x_groupkey}`]),
    numericFilters: options.numericFilters,
    ruleContexts: mergedRuleContexts,
    clickAnalytics: true,
    query: options.query || '',
  }))

  //split queries because algolia allows max 50 in multipleQueries api
  const maxChunkSize = 50
  const results = []
  while (queries.length > 0) {
    const chunk = queries.splice(0, maxChunkSize)
    const chunkResults = await client.multipleQueries(chunk)
    results.push(...chunkResults.results)
  }

  //useful data for analytics
  const objectIDs = flatMap(results, result => {
    return map(result.hits, (hit, index) => {
      return {
        queryID: result.queryID,
        objectID: hit.objectID,
        position: index + 1,
      }
    })
  })

  objectIDsMapUnGrouped = [...objectIDsMapUnGrouped, ...objectIDs]

  return results
}

//used to merge main products in grid with all related color variants
export const mergeProdsAndVariants = (payload,forceFirstVariant = true) => {
  const { products, variants } = payload

  products.hits.forEach((productHit, index) => {
    variants.forEach(variant => {
      const variantHits = variant.hits.filter(
        variantHit => variantHit.x_groupkey === productHit.x_groupkey
      )
      if (variantHits.length > 0) {
        if(!!forceFirstVariant) {
          products.hits[index] = variantHits[0] || productHit
        } else {
          products.hits[index] = productHit
        }
        products.hits[index].colors = [...variantHits]
      }
    })
  })
  return products
}

function sortOptions(facetObject, params) {
  //return only value present in params.order if params?.sortRemainingBy  equals hidden
  if (params?.sortRemainingBy === 'hidden') {
    const filteredObject = {}

    for (const orderValue of params.order) {
      const upperCaseOrderValue = orderValue.toUpperCase()

      for (const facetKey in facetObject) {
        if (
          facetObject.hasOwnProperty(facetKey) &&
          facetKey.toUpperCase() === upperCaseOrderValue
        ) {
          filteredObject[facetKey] = facetObject[facetKey]
        }
      }
    }

    return filteredObject
  }

  // facetObject = facets - params = renderingContent.facetOrdering.values
  if (params?.order) {
    // Create an array to store the items with their corresponding order value
    const orderingArray = params.order
    let orderArray = []
    const valueNotInOrderingArray = []
    // Extract the items and their counts from the input object
    for (const [key, value] of Object.entries(facetObject)) {
      // If the item contains multiple names separated by '|', take the first one for ordering
      const itemName = key.split('|')[0]
      const orderValue =
        orderingArray.indexOf(itemName) !== -1
          ? orderingArray.indexOf(itemName)
          : orderingArray.length

      // Add the item to orderArray only if it's present in orderingArray
      if (orderValue !== orderingArray.length) {
        orderArray.push({ key, orderValue, value })
      } else {
        valueNotInOrderingArray.push({ key, value })
      }
    }
    const sortedValue = Object.fromEntries(
      // sort all the options by alpha or count
      Object.entries(valueNotInOrderingArray).sort((a, b) => {
        return params?.sortRemainingBy === 'alpha'
          ? a[0].split('|')[0].localeCompare(b[0]) // .split('|')[0] to get translated options
          : b[1] - a[1]
      })
    )
    const sortedValueArrat = Object.values(sortedValue)

    // Sort the items based on the order value
    orderArray.sort((a, b) => a.orderValue - b.orderValue)
    sortedValueArrat.forEach(item => {
      orderArray.push(item)
    })
    // Create a new object with the sorted items
    const result = {}
    for (const item of orderArray) {
      result[item.key] = item.value
    }
    return result
  }

  const sortedValue = Object.fromEntries(
    // sort all the options by alpha or count
    Object.entries(facetObject).sort((a, b) => {
      return params?.sortRemainingBy === 'alpha'
        ? a[0].split('|')[0].localeCompare(b[0]) // .split('|')[0] to get translated options
        : b[1] - a[1] || a[0].localeCompare(b[0]) // in order of count, if two value have the same count, sort them alphabetically
    })
  )

  return sortedValue
}

//convert filters to un-translated key for algolia api payload
export const decodeFiltersI18n = idsFiltersCheckedList => {
  loadSectionTranslations('plp')
  const { locale } = wcs.getStoreConfig()
  // find filter translation key to pass to Algolia api
  const filteredKeys = Object.keys(i18n.messages[locale])
  .filter(key => key.startsWith('attributes'))
  .reduce((acc, key) => {
    acc[key] = i18n.messages[locale][key]
    return acc
  }, {})
  const invertedTranslations = invert(filteredKeys)

  idsFiltersCheckedList = idsFiltersCheckedList?.map(item => {
    if (!item?.startsWith('price')) {
      const parts = item.split(':')
      return `${split(invertedTranslations[parts[0]], '?')[0]}:${parts[1]}`
    } else {
      return item
    }
  })
  return idsFiltersCheckedList
}

//get multiple queries result and build merged response with all proper counts and options
export const mergefiltersResult = filtersResult => {
  let [priceRangeCountQuery, allFacets, filteredAppliedFacets, ...rest] =
    filtersResult.length > 2 ? filtersResult : [filtersResult[0], filtersResult[1], [], []]
  //loop all filter group applied and use response to populate count and 0

  if (filteredAppliedFacets.nbHits > 0) {
    rest.forEach(response => {
      if (!isEmpty(response) && !isEmpty(response.facets)) {
        const key = Object.keys(response.facets)[0]
        allFacets.facets[key] &&
          Object.keys(allFacets.facets[key])?.forEach(subKey => {
            if (!filteredAppliedFacets.facets[key]) {
              filteredAppliedFacets.facets[key] = {}
            }
            //if option is present in response assign count, otherwise set to 0:
            filteredAppliedFacets.facets[key][subKey] = Object.keys(response.facets[key]).includes(
              subKey
            )
              ? response.facets[key][subKey]
              : 0
          })
      }
    })

    //for all filters group not applied add all missing options and set count to 0
    for (const key in allFacets.facets) {
      if (!filteredAppliedFacets.facets[key]) {
        filteredAppliedFacets.facets[key] = {}
      }
      for (const subKey in allFacets.facets[key]) {
        if (!filteredAppliedFacets.facets[key][subKey]) {
          filteredAppliedFacets.facets[key][subKey] = 0
        }
      }
    }
  } else filteredAppliedFacets = allFacets

  //force insert photocromic option in lens type facet group (taken from photocromic attribute):
  if (allFacets.facets?.['attributes_translated.PHOTOCHROMIC']?.['TRUE']) {
    filteredAppliedFacets.facets['attributes_translated.LENS_TREATMENT_FACET'][
      i18n.t('attributes_translated.PHOTOCHROMIC')
    ] = filteredAppliedFacets.facets['attributes_translated.PHOTOCHROMIC']['TRUE']
  }

  //update price range facets count based on non-price filters applied
  filteredAppliedFacets.facets['sortPrice_Guest'] =
    priceRangeCountQuery.facets['sortPrice_Guest'] ||
    filteredAppliedFacets.facets['sortPrice_Guest']
  filteredAppliedFacets.facets['sortPrice_EarlyAccess'] =
    priceRangeCountQuery.facets['sortPrice_EarlyAccess'] ||
    filteredAppliedFacets.facets['sortPrice_EarlyAccess']

  return filteredAppliedFacets
}

export const getAlgoliaPlpFacets = async payload => {
  let { idsFiltersCheckedList } = payload

  /* start translate fake hinge filter */
  let minHinge, maxHinge
  idsFiltersCheckedList.map(item => {
    if (item.includes('Hinge')) {
      const matchValue = item.match(/\{([^}]+)\}/)
      const valuesArray = matchValue[1].split(',').map(value => parseInt(value.trim(), 10))
      minHinge = valuesArray[0]
      maxHinge = valuesArray[1]
    }
  })
  if (idsFiltersCheckedList.length)
    idsFiltersCheckedList = idsFiltersCheckedList.filter(item => !item.includes('Hinge'))
  if (minHinge && maxHinge) {
    const hingeValue = getHingeDistanceArrayValue(minHinge, maxHinge)
    hingeValue.forEach(value => {
      idsFiltersCheckedList.push(`Hinge:${value}`)
    })
  }
  /* end translate fake hinge filter */

  const mergedArray = await formatFacetsLogic(idsFiltersCheckedList)
  const facetFilters = mergedArray
  let categoryFacet = ''

  // Remove numericFilters filters from facetFilters
  if (facetFilters) {
    var alphaFilters = facetFilters.filter(element => {
      return (
        !element[0]?.toLowerCase().startsWith('price') &&
        !element[0]?.toLowerCase().startsWith('sale')
      )
    })
  }

  if (payload.currentPlpCategory && isEmpty(payload.searchTerm))
    categoryFacet = `categories:${payload.currentPlpCategory}`
  const isNotChanel = payload.searchTerm ? 'SAFEBRANDSEARCH' : ''
  const mergedRuleContexts = payload.ruleContexts
    ? payload.ruleContexts?.split(',').concat(isNotChanel)
    : [isNotChanel]

  //build queries with all reciprocal filters applied:
  const queries = alphaFilters.map(filter => ({
    indexName: ALGOLIA_CONFIG.groupedIndex,
    filters: categoryFacet,
    clickAnalytics: false,
    facetFilters: alphaFilters.filter(i => i !== filter), //apply all but current filter
    facets: filter[0].split(':')[0], //retrieve data only for current filter
    numericFilters: payload.numericFiltersSelected,
    hitsPerPage: 0,
    query: payload.searchTerm,
    ruleContexts: mergedRuleContexts,
  }))
  //add query with all filters applied (filteredAppliedFacets):
  queries.unshift({
    indexName: ALGOLIA_CONFIG.groupedIndex,
    filters: categoryFacet,
    facetFilters: alphaFilters,
    numericFilters: payload.numericFiltersSelected,
    facets: ['attributes_translated.*', 'prices.*', 'sort*'],
    clickAnalytics: false,
    hitsPerPage: 0,
    query: payload.searchTerm,
    ruleContexts: mergedRuleContexts,
  })
  //add base query to retrieve all facets to start with (allfacets):
  queries.unshift({
    indexName: ALGOLIA_CONFIG.groupedIndex,
    filters: categoryFacet,
    facets: ['attributes_translated.*', 'prices.*', 'sort*'],
    clickAnalytics: false,
    hitsPerPage: 0,
    query: payload.searchTerm,
    ruleContexts: mergedRuleContexts,
  })
  //add query for count with all - numeric, useful to know pricerange to show or not
  queries.unshift({
    indexName: ALGOLIA_CONFIG.groupedIndex,
    filters: categoryFacet,
    facetFilters: alphaFilters,
    facets: ['prices.*', 'sort*'],
    clickAnalytics: false,
    hitsPerPage: 0,
    query: payload.searchTerm,
    ruleContexts: mergedRuleContexts,
  })

  const filtersResult = await client.multipleQueries(queries)

  const res = mergefiltersResult(filtersResult.results)
  if (res.nbHits == 0) return { nbHits: 0 }

  //order facets according renderingContent order in response:
  if (res.facets['attributes_translated.HINGE_DISTANCE'])
    sessionStorage.setItem(
      'hingeDistanceArray',
      Object.keys(res.facets['attributes_translated.HINGE_DISTANCE'])
    )
  const orderedFacets = {}
  if (res.renderingContent.facetOrdering) {
    //force inserting sortDiscount_Guest and sortDiscount_EarlyAccess in facets
    res.renderingContent.facetOrdering.facets.order.push('sortDiscount_Guest')
    res.renderingContent.facetOrdering.facets.order.push('sortDiscount_EarlyAccess')

    res.renderingContent.facetOrdering.facets.order.forEach(facetKey => {
      if (res.facets.hasOwnProperty(facetKey)) {
        res.facets[facetKey] = sortOptions(
          res.facets[facetKey],
          res.renderingContent.facetOrdering.values[facetKey]
        )
        orderedFacets[facetKey] = res.facets[facetKey]
      }
    })
    res.facets = orderedFacets
  }
  // remove gender facet if we are in gender category
  if (categoryFacet.includes('gender')) {
    delete res.facets['attributes_translated.GENDER']
  }
  return res
}

export const getRelatedProducts = async params => {
  const { objectIDs } = params
  const indexName = ALGOLIA_CONFIG.groupedIndex
  const res = await clientRelated.getRelatedProducts(indexName, objectIDs)
  return res
}
export const getFrequentlyBoughtTogether = async params => {
  const { objectIDs } = params
  const indexName = ALGOLIA_CONFIG.groupedIndex
  const res = await clientRelated.getFrequentlyBoughtTogether(indexName, objectIDs)
  return res
}

const sortedNamesIndex = {
  3: window.algoliaConfig.isEarlyAccessParam ? 'earlyaccess_asc' : 'standard_asc',
  4: window.algoliaConfig.isEarlyAccessParam ? 'earlyaccess_desc' : 'standard_desc',
  1: 'new',
}

export const setGroupedIndex = function(sortOrder) {
  if (!sortOrder || sortOrder === 'default') {
    index = client.initIndex(ALGOLIA_CONFIG.groupedIndex)
    return
  }
  index = client.initIndex(`${ALGOLIA_CONFIG.groupedIndex}__${sortedNamesIndex[sortOrder]}`)
}

export const getUngroupedIndex = function(sortOrder) {
  if (!sortOrder || sortOrder === 'default') {
    return ALGOLIA_CONFIG.ungroupedIndex
  }
  return `${ALGOLIA_CONFIG.ungroupedIndex}__${sortedNamesIndex[sortOrder]}`
}

//format the applied filters array to satisfy AND vs OR conditions by nesting selected options of same groupname (= OR) (cfr Algolia doc)
//and convert filter label translation value to key
export const formatFacetsLogic = async (idsFiltersCheckedList = []) => {
  idsFiltersCheckedList = await decodeFiltersI18n(idsFiltersCheckedList)

  let mergedArray = []
  if (!isEmpty(idsFiltersCheckedList)) {
    const groupedItems = {}
    for (const str of idsFiltersCheckedList) {
      if (!str) {
        return []
      }
      const [key, value] = str.split(':')
      const groupKey = key.trim()

      if (!groupedItems[groupKey]) {
        groupedItems[groupKey] = [str]
      } else {
        groupedItems[groupKey].push(str)
      }
    }

    for (const groupKey in groupedItems) {
      mergedArray.push(groupedItems[groupKey])
    }
    // todo: modify logic with sortDiscount_Guest and sortDiscount_EarlyAccess attributes
    // Find index to add new element attributes_translated.ON_SALE:TRUE and attributes_translated.ON_EA_SALE:TRUE if combined filter attributes_translated.SALE is present
    const indexSale = findIndex(mergedArray, item => {
      return some(item, element => element.startsWith('attributes_translated.SALE'))
    })
    // Add new elements in the matching array and remove combined filter attributes_translated.SALE
    const isEarlyAccessUser = window.algoliaConfig?.isEarlyAccessParam

    if (indexSale !== -1) {
      if (isEarlyAccessUser) {
        update(mergedArray, `[${indexSale}]`, newArray => [
          ...newArray,
          'sortDiscount_Guest>0',
          'sortDiscount_EarlyAccess>0',
        ])
      } else {
        update(mergedArray, `[${indexSale}]`, newArray => [...newArray, 'sortDiscount_Guest>0'])
      }
      mergedArray = map(mergedArray, innerList =>
        filter(innerList, item => item !== 'attributes_translated.SALE:TRUE')
      )
    }

    //add unisex if male or female are selected
    const indexGender = findIndex(mergedArray, item => {
      return some(item, element => element.includes('MALE') || element.includes('FEMALE'))
    })
    if (indexGender !== -1) {
      update(mergedArray, `[${indexGender}]`, newArray => [
        ...newArray,
        `attributes_translated.GENDER:${i18n.t('plp_gender_unisex')}`,
      ])
    }

    //add photocromic true if lens treatment photocromic option is selected:
    const indexPhotocromic = findIndex(mergedArray, item => {
      return some(item, element => element.includes(i18n.t('attributes_translated.PHOTOCHROMIC')))
    })
    if (indexPhotocromic !== -1) {
      update(mergedArray, `[${indexPhotocromic}]`, newArray => [
        ...newArray,
        `attributes_translated.PHOTOCHROMIC:TRUE`,
      ])
    }

    return mergedArray
  }
  return mergedArray
}

// init algolia analytics
aa('init', {
  appId: ALGOLIA_CONFIG.applicationId,
  apiKey: ALGOLIA_CONFIG.apiKey,
  useCookie: true,
})

const userToken = aa('getUserToken')

export const algoliaAnalyticsEvents = params => {
  if (params.eventType === 'Click' && params.eventName == 'PDP Grouped') {
    params.index = ALGOLIA_CONFIG.groupedIndex
    params.queryId = queryIDGrouped
  } else if (params.eventType === 'variantHover' || (params.eventType === 'Click' && params.eventName == 'PDP Ungrouped')) {
    params.index = ALGOLIA_CONFIG.ungroupedIndex
    const objectID = params.objectIDs[0]
    params.queryId = find(objectIDsMapUnGrouped, { objectID })
      ? find(objectIDsMapUnGrouped, { objectID }).queryID
      : ''
  }

  const eventParams = {
    queryID: params.queryId,
    objectIDs: params.objectIDs,
    index: params.index,
    userToken: userToken,
    eventName: params.eventName,
  }

  if (params.eventAlgolia !== 'viewedObjectIDs') {
    eventParams.positions = [params.position || 0]
  }

  aa(params.eventAlgolia, eventParams)
}

export const tealiumAnalyticsEvents = params => {
  const objectID = params.objectID[0]
  const mainProductObjectID = params.mainProduct
  let colorPosition = find(objectIDsMapUnGrouped, { objectID })
    ? find(objectIDsMapUnGrouped, { objectID }).position
    : ''

  const analyticsParams = {
    id: 'Click',
    eventName: isSearch ? 'Search – Go to product' : 'PLP – Go to product',
    data_element_id: params.dataElementId ? params.dataElementId : '',
    data_description: params.dataDescription ? params.dataDescription : '',
    objectID: objectID,
    grouped_queryID: queryIDGrouped,
    ungrouped_queryID: find(objectIDsMapUnGrouped, { objectID })
      ? find(objectIDsMapUnGrouped, { objectID }).queryID
      : '',
    grouped_indexName: ALGOLIA_CONFIG.groupedIndex,
    ungrouped_indexName: ALGOLIA_CONFIG.ungroupedIndex,
    grouped_position: find(objectIDsMapPositionGrouped, { objectID: mainProductObjectID })
      ? find(objectIDsMapPositionGrouped, { objectID: mainProductObjectID }).index
      : '',
    ungrouped_position: colorPosition,
  }

  sessionAlgoliaParams(analyticsParams)

  window.tealium_data2track.push(analyticsParams)
}

function sessionAlgoliaParams(params) {
  // set session storage for add to bag and pdp landing page
  let sessionDataAlgolia = {
    queryIDAlgolia_grouped: queryIDGrouped,
    queryIDAlgolia_ungrouped: JSON.stringify(objectIDsMapUnGrouped),
    indexNameAlgolia_grouped: ALGOLIA_CONFIG.groupedIndex,
    indexNameAlgolia_ungrouped: ALGOLIA_CONFIG.ungroupedIndex,
    objectdIDsAlgolia_grouped: params.objectID,
    positionAlgolia_grouped: params.grouped_position,
    positionAlgolia_ungrouped: params.ungrouped_position,
  }

  let sessionDataAlgoliaString = JSON.stringify(sessionDataAlgolia)
  sessionStorage.setItem('sessionDataAlgolia', sessionDataAlgoliaString)
}

function getHingeDistanceArrayValue(min, max) {
  const arrayOfStrings = sessionStorage.getItem('hingeDistanceArray').split(',')
  const arrayOfNumbers = arrayOfStrings.map(str => parseFloat(str))
  const extractedNumbers = arrayOfNumbers.filter(num => num >= min && num <= max)

  return extractedNumbers
}

export const algoliaGetRule = async (rule, maxProduct) => {
  const query = ''
  const res = []
  res.hits = []
  res.userData = []
  await index
    .search(query, {
      hitsPerPage: maxProduct,
      ruleContexts: [rule],
    })
    .then(result => {
      result.hits?.map(hit => res.hits.push(hit))
      result.userData?.map(userData => res.userData.push(userData))
    })
    .catch(error => {
      console.error(error)
    })
  return res
}

export const algoliaGetTrendingFacets = async () => {
  const res = {}
  res.hits = []

  await clientRelated
    .getTrendingFacets([
      {
        indexName: ALGOLIA_CONFIG.groupedIndex,
        facetName: 'attributes.BRAND',
        maxRecommendations: 10,
      },
    ])
    .then(result => {
      result.results[0].hits.map(hit => res.hits.push(hit))
    })
    .catch(error => {
      console.error(error)
    })

  return res
}

export const algoliaGetTrendingItems = async () => {
  const res = []
  res.hits = []

  await clientRelated
    .getTrendingItems([
      {
        indexName: ALGOLIA_CONFIG.groupedIndex,
        maxRecommendations: 20,
      },
    ])
    .then(result => {
      result.results[0].hits.map(hit => res.hits.push(hit))
    })
    .catch(error => {
      console.error(error)
    })

  return res
}
export const algoliaGetTrendingItemsContent = async () => {
  const res = []
  res.hits = []

  await clientRelated
    .getTrendingItems([
      {
        indexName: ALGOLIA_CONFIG.contentIndex,
      },
    ])
    .then(result => {
      result.results[0].hits.map(hit => res.hits.push(hit))
    })
    .catch(error => {
      console.error(error)
    })

  return res
}

export const algoliaGetSuggestion = async productId => {
  const query = ''
  const res = []
  res.hits = []

  await clientRelated
    .getRelatedProducts([
      {
        indexName: ALGOLIA_CONFIG.groupedIndex,
        objectID: productId,
      },
    ])
    .then(result => {
      result.results[0].hits.map(hit => res.hits.push(hit))
    })
    .catch(error => {
      console.error(error)
    })

  return res
}

export const algoliaGetFrequentlyBoughtTogether = async productId => {
  const query = ''
  const res = []
  res.hits = []

  await clientRelated
    .getFrequentlyBoughtTogether([
      {
        indexName: ALGOLIA_CONFIG.groupedIndex,
        objectID: productId,
      },
    ])
    .then(result => {
      result.results[0].hits.map(hit => res.hits.push(hit))
    })
    .catch(error => {
      console.error(error)
    })

  return res
}

//change price filter format to be compoatible with api call to be performed:
function replacePriceCall(filter) {
  return filter.includes('>')
    ? filter.replace('price:', `${sortPrice} `)
    : filter.replace('price:', `${sortPrice}: `)
}

const sortPrice = window.algoliaConfig?.isEarlyAccessParam
  ? 'sortPrice_EarlyAccess'
  : 'sortPrice_Guest'

//algolia instant search suggestions grouped and query suggestion
export const algoliaSuggestionsResults = async (searchTerm, page, facetsSelected, startPage) => {
  //build options and params for grouped product search

  const { idsFiltersCheckedList, orderBy } = facetsSelected || []
  const mergedArray = await formatFacetsLogic(idsFiltersCheckedList)
  const facetFilters = mergedArray

  const numericFiltersSelected = buildNumericFilters(idsFiltersCheckedList)

  if (facetFilters) {
    var filteredArray = facetFilters.filter(
      element => !element[0]?.toLowerCase().startsWith('price')
    )
  }

  const options = {
    query: searchTerm,
    hitsPerPage: 6,
    page: page,
    facetFilters: [],
    attributesToRetrieve: ['*'],
    facetFilters: filteredArray,
    numericFilters: numericFiltersSelected,
    removeStopWords: true,
    ruleContexts: 'SAFEBRANDSEARCH',
    orderBy,
  }
  const addParams = {
    startPage: startPage, //todo pass dynamic start page as done for plp
  }
  const products = await algoliaPlpGroupedSearch({ options, addParams })
  const userData = products?.userData || null
  const redirect = products?.renderingContent?.redirect || null

  //retrieve and merge variants for all products
  if (!isEmpty(products)) {
    var productsAndVariants = await getProductsVariants({ mainProducts: products, options })
  }

  //build search index for query suggestions and content suggestions multiple queries
  const searchIndex = [
    {
      indexName: `${ALGOLIA_CONFIG.groupedIndex}_query_suggestions`,
      params: {
        query: searchTerm,
        hitsPerPage: 10,
        ruleContexts: ['SAFEBRANDSEARCH'],
      },
    },
    {
      indexName: `${indexEnv}_sgh_${currentLocale}__contents`,
      params: {
        query: searchTerm,
        attributesToRetrieve: ['title', 'url'],
        attributesToHighlight: ['title'],
        attributesToSnippet: ['title'],
        hitsPerPage: 6,
      },
    },
  ]

  const otherSuggestions = await client.multipleQueries(searchIndex)

  return {
    productsFinal: productsAndVariants,
    suggestedWords: otherSuggestions.results[0],
    contentResult: otherSuggestions.results[1],
    userData: userData,
    redirect: redirect,
  }
}

//algolia instant search content suggestions
export const algoliaSuggestionsContent = async (searchTerm, page = 0) => {
  const contentIndex = [
    {
      indexName: `${indexEnv}_sgh_${currentLocale}__contents`,
      params: {
        query: searchTerm,
        hitsPerPage: 6,
        page: page,
      },
    },
  ]
  return await client.multipleQueries(contentIndex)
}

//algolia simple ungrouped call for carousels
export const algoliaSimpleUnGrouped = async products => {
  const { hits } = products
  const queries = hits.map(hit => ({
    hitsPerPage: 20,
    ruleContexts: ['SAFEBRANDSEARCH'],
    indexName: ALGOLIA_CONFIG.ungroupedIndex,
    facetFilters: [`x_groupkey: ${hit.x_groupkey}`],
    numericFilters: [[]],
    query: '',
  }))
  return await client.multipleQueries(queries)
}
