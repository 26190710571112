import axios from 'axios'

export const getCountryList = async () => {
  const countryListUrl = `/sghstatichtml/assets/country-selector/wcs-countries.json`

  let data = {}

  try {
    data = await axios.get(countryListUrl)
    return data.data
  } catch (error) {
    throw new Error('error on retrieving country list translations', error)
  }
}
