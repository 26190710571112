import axios from 'axios'
import { getStoresListNormalized } from '../normalizer'
import wcs from './index'

export const getStoreLocatorList = async ({ position }) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const { storeId, langId } = wcs.getStoreConfig()
  const endpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getStoreLocatorList')
      : `${domain}/AjaxSGHFindPhysicalStoreLocations?latitude=${position.lat}&longitude=${position.lng}&radius=${position.rad}&langId=${langId}&storeId=${storeId}`

  return getStoresListNormalized(await axios.get(endpoint))
}

export async function getSignedMapsCall(unsignedMapsUrl) {
  const { storeId } = wcs.getStoreConfig()
  const signUrlREST = `${
    window.location.origin
  }/wcs/resources/store/${storeId}/googleurlsigner/signurl?url=${encodeURIComponent(
    unsignedMapsUrl
  )}`
  return axios.get(signUrlREST)
}
