import qs from 'qs'
import urlParser from 'url'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import { getCurrentCountry } from '../../../scripts/libs/utils/currentCountry'
import { CHECKOUT_CONTAINER, PDP_CONTAINER } from './const'
import { getSAHingeFilterId } from './const'

const hingeFilterIdSA = getSAHingeFilterId()

export function addParamsToUrl(url, paramsObj) {
  const { query, ...urlObj } = urlParser.parse(url)
  urlObj.search = qs.stringify({
    ...qs.parse(query),
    ...qs.parse(paramsObj),
  })
  return urlParser.format(urlObj)
}

export function updateUrl(params) {
  const currentParams = qs.parse(decodeURIComponent(window.location.search), {
    ignoreQueryPrefix: true,
  })
  const finalParams = {
    ...currentParams,
    ...params,
  }

  if (finalParams.currentPage == 1) {
    delete finalParams.currentPage
  }
  // add custom encoder to add double encoding of + and &
  const customEncoder = str => {
    return encodeURIComponent(str)
      .replace(/%2B/g, '+') // Revert %2B (encoded '+') back to '+'
      .replace(/%26/g, '%2526') // Revert %26 (encoded '&') back to '%2526'
  }
  const final = qs.stringify(finalParams, { arrayFormat: 'repeat', encoder: customEncoder })
  const res = window.location.pathname
  const finalString = final ? `?${final}` : ''

  window.history.pushState('', '', res + finalString)
}

export function emptyUrl() {
  window.history.pushState('', '', window.location.pathname)
}

export function deleteParamUrl(param) {
  var url = new URL(window.location.href)
  url.searchParams.delete(param)
  window.history.replaceState({}, document.title, url)
}

//get from url extraFacets not selectable in UI (custom price filter SGHDP-13408)
export function getExtraFacets() {
  const currentParams = qs.parse(decodeURIComponent(window.location.search), {
    ignoreQueryPrefix: true,
  })
  let extraFacets = []
  //Custom price filter extrafacet
  let priceFacet = isArray(currentParams.facet)
    ? find(currentParams.facet, facet => {
        return facet.substr(0, 5) === 'price'
      })
    : currentParams.facet && currentParams.facet.substr(0, 5) === 'price'
    ? currentParams.facet
    : undefined
  priceFacet && extraFacets.push(priceFacet)
  //sizeAdvisor hinge range extrafacet:
  let hingeRangeFacet = isArray(currentParams.facet)
    ? find(currentParams.facet, facet => {
        return facet.includes(hingeFilterIdSA)
      })
    : currentParams.facet && currentParams.facet.includes(hingeFilterIdSA)
    ? currentParams.facet
    : undefined
  hingeRangeFacet && extraFacets.push(hingeRangeFacet)
  return extraFacets
}

export function isUserOnPdpPage() {
  const pdpContainer = document.querySelector(PDP_CONTAINER)
  return pdpContainer !== null
}

export function isUserOnCheckoutPage() {
  const checkoutContainer = document.querySelector(CHECKOUT_CONTAINER)
  return checkoutContainer !== null && checkoutContainer !== undefined
}

export function redirectTo404() {
  window.location.href = `/${getCurrentCountry()}/404`
}

export function isValidUrl(url) {
  try {
    new URL(url)
  } catch (e) {
    return false
  }
  return true
}

export function isLocalHost() {
  // Add your local domains here
  const LOCAL_DOMAINS = ['localhost']

  if (LOCAL_DOMAINS.includes(window.location.hostname)) return true

  return false
}
