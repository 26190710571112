import bcrypt from 'bcryptjs'

export default async (password, saltLength) => {
  return new Promise((resolve, reject) => {
    bcrypt.genSalt(saltLength, (err, salt) => {
      if (!err) {
        bcrypt.hash(password, salt, (err, hash) => {
          if (!err) {
            resolve(hash)
          }
        })
      } else {
        reject(err)
      }
    })
  })
}
