import wcs from './index'
import { algoliaSuggestionsResults, algoliaSuggestionsContent } from '../utils/algoliaIntegration'
import { getAlgoliaSuggestionsNormalized, getAlgoliaContentNormalized } from '../algolia-normalizer'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

const useAlgolia = window.algoliaConfig?.isActive || false
let currentPlpCategory = ''

export const getSuggestions = async (searchTerm, page, facetsSelected, startPage) => {
  //perform required queries to algolia to retrieve prodcuts, suggested words, content and user data:
  let {
    productsFinal,
    suggestedWords,
    contentResult,
    userData,
    redirect,
  } = await algoliaSuggestionsResults(searchTerm, page, facetsSelected, startPage)

  //return normalized data
  return getAlgoliaSuggestionsNormalized(
    productsFinal,
    suggestedWords,
    contentResult,
    userData,
    redirect
  )
}

export const getSuggestedContent = async (searchTerm, page) => {
  const response = await algoliaSuggestionsContent(searchTerm, page)
  let normalizedContentItems = {}
  if (!isEmpty(response.results[0].hits)) {
    normalizedContentItems = getAlgoliaContentNormalized(response.results[0])
  }
  return normalizedContentItems
}

export const originalSuggestionsTrack = (funct, Search_Type, searchTerm, Search_ResultItemsQnt) => {
  funct.trackEvent({
    event: 'Event',
    value: {
      Events_SearchRun: '1',
      Search_Keyword: searchTerm,
      Search_KeyActual: searchTerm,
      Search_Type: Search_Type,
      Search_View: 'SERB',
      Search_ResultItemsQnt: Search_ResultItemsQnt,
      Search_FacetValues_String: '',
    },
  })
}

const debouncedSuggestionsTrack = debounce(originalSuggestionsTrack, 2000)

export { debouncedSuggestionsTrack as SuggestionsTrack }
