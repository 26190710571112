import wcs from './../wcs/index'

export const getCurrentCountry = () => {
  const { storeId, locale, globalCountry } = wcs.getStoreConfig()
  let country = null
  switch (storeId) {
    case '10154': {
      country = locale == 'en_CA' ? 'ca-en' : 'ca-fr'
      break
    }
    case '11352': {
      country = 'uk'
      break
    }
    case '19001': {
      country = 'nl'
      break
    }
    default: {
      country = globalCountry.toLowerCase()
    }
  }

  return country
}

export const getGlobalCountry = () => {
  const { storeId, globalCountry } = wcs.getStoreConfig()
  
  if (storeId === '11352') return 'uk'
  else return globalCountry.toLowerCase()
}

export const getCurrentLocale = () => {
  const { locale } = wcs && wcs.getStoreConfig()
  const returnedLocale = locale || 'en_US'
  const locales = {
    de_DE: 'de-DE',
    en_AU: 'en-AU',
    en_CA: 'en-CA',
    en_NZ: 'en-NZ',
    en_GB: 'en-UK',
    en_US: 'en-US',
    es_ES: 'es-ES',
    es_MX: 'es-MX',
    fr_CA: 'fr-CA',
    fr_FR: 'fr-FR',
    nl_NL: 'nl-NL',
    pt_BR: 'pt-BR',
    en_WW: 'en-US',
  }
  return locales[returnedLocale]
}

export const getCurrentDistanceMeasureUnit = () => {
  const { globalCountry } = wcs.getStoreConfig()
  const countryWithKm = ['nz', 'au', 'de', 'es', 'fr', 'mx', 'br', 'nl']

  return countryWithKm.includes(globalCountry.toLowerCase()) ? 'km' : 'miles'
}

export function getCurrencySymbol() {
  const { currency } = wcs && wcs.getStoreConfig()
  return (0)
    .toLocaleString(getCurrentLocale(), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}
