export const heightTransitionHandlers = {
    enter(el: HTMLElement) {
        el.style.height = '0';
        requestAnimationFrame(() => {
            el.style.height = el.scrollHeight + 'px';
            el.addEventListener(
                'transitionend',
                () => {
                    el.style.height = 'auto';
                },
                { once: true }
            );
        });
    },
    leave(el: HTMLElement) {
        el.style.height = el.scrollHeight + 'px';
        requestAnimationFrame(() => {
            el.style.height = '0';
        });
    },
};
